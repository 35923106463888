import axios from "axios";
import { BACKEND_URL } from "./config";
import cookies from "vue-cookies";

if (cookies.get("user"))
  axios.defaults.headers.common["Authorization"] = cookies.get("user").token;

async function create(opts) {
  const res = await axios.post(`${BACKEND_URL}/tunecasts`, { ...opts });
  return res.data;
}

async function clearChat(tunecast) {
  const res = await axios.delete(`${BACKEND_URL}/tunecasts/${tunecast}/chat`);
  return res.data;
}

async function update(id, opts) {
  const res = await axios.post(`${BACKEND_URL}/tunecasts/update`, {
    id,
    ...opts,
  });
  return res.data;
}

async function updateStatus(id) {
  const res = await axios.put(`${BACKEND_URL}/tunecasts/status`, { id });
  return res.data;
}

async function fetchTunecast(id) {
  const res = await axios.get(`${BACKEND_URL}/tunecasts/${id}`);
  return res.data;
}

async function pollRealtime() {
  const res = await axios.get(`${BACKEND_URL}/poll`);
  return res.data;
}

export async function checkSubdomain(subdomain) {
  const res = await axios.get(
    `${BACKEND_URL}/tunecasts/subdomain?subdomain=${subdomain}`
  );
  return res.data;
}

export async function fetchStats(tunecast) {
  const res = await axios.get(`${BACKEND_URL}/stats?tunecast=${tunecast}`);
  return res.data;
}

async function uploadFile(file) {
  const formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(`${BACKEND_URL}/file-upload`, formData);

  return res.data.file;
}

export async function fetchTunecasts() {
  const res = await axios.get(`${BACKEND_URL}/tunecasts`);
  return res.data;
}

// async function createTunecastV2(tunecast_data) {
//   const res = await axios.post(`${BACKEND_URL}/v2/tunecasts`, tunecast_data);
//   return res.data.tunecast;
// }

// async function updateTunecastV2(id, tunecast_data) {
//   const res = await axios.put(`${BACKEND_URL}/v2/tunecasts/update`, {
//     id,
//     ...tunecast_data,
//   });

//   return res.data;
// }

export default {
  create,
  update,
  clearChat,
  updateStatus,
  fetchTunecasts,
  fetchTunecast,
  pollRealtime,
  checkSubdomain,
  fetchStats,
  uploadFile,
  // createTunecastV2,
  // updateTunecastV2,
};